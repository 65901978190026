@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F5F8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.adv-icon {
  height: 5rem;
}

.button-gradient {
  background: linear-gradient(270deg, rgba(90, 226, 140, 0.89) 4.29%, rgba(211, 227, 130, 0.45) 153.33%),
    linear-gradient(269.91deg, #1FE121 4.36%, rgba(241, 241, 241, 0.45) 111.19%);
}

.checkout-block-shadow {
  box-shadow: 1px 1px 10px 0px #0000001A;
}

.required:after {
  content: " *";
  color: red;
}


#header {
  transition: transform .6s ease-in-out;
}

.scroll-area {
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  overflow: auto hidden;
}

.scroll-area::-webkit-scrollbar {
  display: none;
}


::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 100% !important;
  background: transparent !important;
  border: none !important;
  border-radius: 0.5rem !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.react-tel-input:hover .flag-dropdown {
  background-color: transparent !important;
}